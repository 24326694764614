import React from 'react';
import {
  ComponentPreview,
  ComponentWithLabel,
  DontDo,
  DontDoItem,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from 'docComponents';
import { Checkbox, CheckboxGroup } from 'hudl-uniform-ui-components';
import SectionSize from '../partials/_SectionSize';
import SectionUsage from '../partials/_SectionUsage';
import SectionPlatform from '../partials/_SectionPlatform';
import pageHeroData from '../../../../data/pages/components.yml';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checkboxValues: [],
    };
  }

  onCheckChange(value) {
    let newArray = this.state.checkboxValues;
    if (newArray.includes(value)) {
      newArray = newArray.filter(checkedValue => checkedValue !== value);
    } else {
      newArray.push(value);
    }
    this.setState({ checkboxValues: newArray });
  }

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Checkbox"
        designCode
        subnav="components">
        <PageHero
          heroData={pageHeroData}
          tierTwo="Forms"
          tierThree="Checkbox"
        />

        <PageNavigation>
          <PageNavigationLink>Size</PageNavigationLink>
          <PageNavigationLink>State</PageNavigationLink>
          <PageNavigationLink>Mobile</PageNavigationLink>
          <PageNavigationLink>Usage</PageNavigationLink>
          <PageNavigationLink>Microcopy</PageNavigationLink>
          <PageNavigationLink>Platform</PageNavigationLink>
        </PageNavigation>

        <SectionSize text="Checkbox" />

        <Section title="State">
          <Paragraph>
            A checkbox’s state depends on the interaction itself or a submission
            error.
          </Paragraph>
          <ComponentPreview name="CheckboxState">
            <ComponentWithLabel>
              <CheckboxGroup
                header="Recipients"
                onChange={this.onCheckChange.bind(this)}
                valuesChecked={this.state.checkboxValues}>
                <Checkbox label="Coaches" value="coaches" />
                <Checkbox label="Athletes" value="athletes" />
                <Checkbox label="Opposing Team" value="opposingTeam" disabled />
              </CheckboxGroup>
            </ComponentWithLabel>
          </ComponentPreview>
          <Paragraph>
            The actual <em>focus</em> state is sorted for you, so anyone
            navigating via keyboard or screen reader is set. A disabled checkbox
            prevents the user from interacting with a field until certain
            conditions are met. It’s a good way to prevent invalid submissions,
            but should be used sparingly.
          </Paragraph>
        </Section>

        <Section title="Mobile">
          <Paragraph>
            Use Uniform checkboxes for both Apple and Android devices.
            Checkboxes should be used within forms. If there are more than six
            options, display the options in a separate{' '}
            <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/views/tables/">
              table view
            </Link>
            .
          </Paragraph>
          <Paragraph>
            When selecting multiple items to perform an action, use a{' '}
            <Link href="/components/select-mark/design/">select mark</Link> in
            place of the checkbox.
          </Paragraph>
        </Section>
        <SectionUsage />

        <Section title="Microcopy">
          <SectionSubhead>Labels</SectionSubhead>
          <Paragraph>
            A label should clearly and concisely describe the field’s purpose
            with no more than a few words.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="make the label a complete sentence telling the user what to do."
              img="checkbox-label-dont"
            />
            <DontDoItem
              type="do"
              text="name exactly what belongs in that field."
              img="checkbox-label-do"
            />
          </DontDo>
          <Paragraph>
            Check{' '}
            <Link href="/words/content-elements/labels-non-label-text/">
              UI label guidelines
            </Link>{' '}
            for more detail on case and punctuation.
          </Paragraph>
          <Paragraph>
            For required fields,{' '}
            <strong>place an asterisk at the beginning of the label.</strong>{' '}
            There’s no need to spell out ‘required’ and extend the length of
            that label.
          </Paragraph>
        </Section>

        <SectionPlatform />
      </PageWithSubNav>
    );
  }
}
