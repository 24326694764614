import React from 'react';
import { Paragraph, Section, SectionSubhead } from 'docComponents';

const SectionUsage = ({ hasLabel, hasMobile }) => {
  return (
    <Section title="Usage">
      {hasMobile && (
        <div>
          <SectionSubhead>Keyboards</SectionSubhead>
          <Paragraph>
            Use the keyboard intended for the type of data entry, like the
            numeric keyboard when entering a phone number, or the alphanumeric
            keyboard when entering an email address.
          </Paragraph>
          <SectionSubhead>Pickers</SectionSubhead>
          <Paragraph>
            Pickers make entering date, time, and combined date and time easier
            than entering each character individually.
          </Paragraph>
        </div>
      )}
      {hasLabel && (
        <div>
          <SectionSubhead>Labels</SectionSubhead>
          <Paragraph>
            Labels are <strong>mandatory</strong> on all fields, but they don’t
            always have to be visible. Even hidden labels make a difference in
            accessibility.
          </Paragraph>
          <Paragraph>
            Labels should always appear left-aligned above the field. They
            should provide enough direction that no placeholder copy is needed.
          </Paragraph>
        </div>
      )}
      <SectionSubhead>Layout</SectionSubhead>
      <Paragraph>
        Limit yourself to <strong>one field per row.</strong>
      </Paragraph>
      <SectionSubhead>Disabled States</SectionSubhead>
      <Paragraph>
        An alternative to disabled states is hiding the field until it becomes
        relevant. It reduces cognitive load and creates a much clearer path to
        completing the form—especially as fields change with preceding
        selections.
      </Paragraph>
    </Section>
  );
};

export default SectionUsage;
