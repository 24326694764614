import React from 'react';
import { Section, Paragraph, Link } from 'docComponents';

const SectionSize = ({ text }) => {
  return (
    <Section title="Size">
      <Paragraph>
        {text} size (x-small, small, medium, large) and the form’s density
        (standard, compact) are dictated by the{' '}
        <Link href="/components/forms/form-modifier" isDesignCodeLink>
          form modifier component
        </Link>
        . Check those guidelines for more detailed recommendations.
      </Paragraph>
    </Section>
  );
};

export default SectionSize;
